<template>
  <v-card
    v-if="userProfile.occupation_sector.sector.name === 'MARKETING'"
    class="d-flex flex-column"
  >
    <div
      class="d-flex justify-space-between pa-7"
      style="width: 100%;"
    >
      <h3 style="font-size: 18px; font-weight: 600;">
        Painel de solicitações
      </h3>
      <div
        style="gap: 20px;"
        class="d-flex"
      >
        <v-btn
          class=""
          outlined
          :color="filterButtonColor"
          @click="openshowModalFilterSolicitation"
        >
          <v-icon
            size="22"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span class="button-text-actions">FILTRAR</span>
        </v-btn>
        <v-btn
          v-if="isFiltered"
          outlined
          color="error"
          @click="clearFilters"
        >
          <v-icon
            size="22"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span class="button-text-actions">LIMPAR</span>
        </v-btn>
      </div>
    </div>
    <div
      class="d-flex pr-7"
    >
      <div style="width: 40%;">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
        >
          <div
            class="d-flex py-2 align-center"
            :class="{ isActive: tab.isActive, isntActive: !tab.isActive }"
            style="gap: 10px; width: auto;"
          >
            <v-icon
              class="ml-3"
              :class="{ isActiveText: tab.isActive }"
              style="cursor: pointer;"
              @click="setActiveTab(index)"
            >
              {{ tab.icon }}
            </v-icon>
            <span
              style="font-size: 16px; cursor: pointer;"
              :class="{ isActiveText: tab.isActive }"
              @click="setActiveTab(index)"
            >{{ tab.title }}</span>
            <div
              :style="{
                background: tab.badgeBackgroundColor,
              }"
              style="padding: 10px 10px; align-items: center; justify-content: center; border-radius: 30px; width: 35px;  cursor: pointer;"
              class="d-flex px-3 py-1"
              @click="setActiveTab(index)"
            >
              <span
                style="font-size: 12px; font-weight: 500;"
                :style="{ color: tab.badgeTextColor }"
              >{{ tab.solicitation_quantity }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        style="border-radius: 10px; width: 100%;"
      >
        <div
          v-if="FilterActiveTab.key === 'awaiting_approval'"
          class="d-flex px-16 mb-4"
          style="justify-content: center; align-items: center; gap: 50px;"
        >
          <div
            v-for="(tab, index) in SecondaryTabs"
            :key="index"
          >
            <div
              class="d-flex py-2 align-center"
              style="gap: 10px; width: auto;"
            >
              <v-icon
                :class="{ isActiveText: tab.isActive }"
                style="cursor: pointer;"
                @click="setSecondaryActiveTab(index)"
              >
                {{ tab.icon }}
              </v-icon>
              <span
                style="font-size: 16px; cursor: pointer;"
                :class="{ isActiveText: tab.isActive, isSecondaryActive: tab.isActive, isntSecondaryActive: !tab.isActive }"
                @click="setSecondaryActiveTab(index)"
              >{{ tab.title }}</span>
              <div
                :style="{
                  background: tab.badgeBackgroundColor,
                }"
                style="padding: 10px 10px; align-items: center; justify-content: center; border-radius: 30px; width: 35px;  cursor: pointer;"
                class="d-flex px-3 py-1"
                @click="setSecondaryActiveTab(index)"
              >
                <span
                  style="font-size: 12px; font-weight: 500;"
                  :style="{ color: tab.badgeTextColor }"
                >{{ tab.solicitation_quantity }}</span>
              </div>
            </div>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="listOfFilteredItems"
          :loading="loadingTable"
          loading-text="Carregando dados..."
          class="text-no-wrap"
        >
          <template v-slot:item.slm="{ item }">
            <span style="color: #9A62FD; font-weight: 500; font-size: 14px;">
              0{{ item.slm ? item.slm : '' }}
            </span>
          </template>
          <template v-slot:item.created_at="{ item }">
            <span>
              {{ item.created_at ? formatToDayMonthYear(item.created_at) : 'A definir' }}
            </span>
          </template>
          <template v-slot:item.subject="{ item }">
            <div
              class="d-flex my-6"
              style="align-items: center; gap: 10px;"
            >
              <v-icon :color="returnIconOrColorBasedOnSubject('color', item.subject)">
                {{ returnIconOrColorBasedOnSubject('icon', item.subject) }}
              </v-icon>
              <span>
                {{ item.subject ? item.subject : '-' }}
              </span>
            </div>
          </template>
          <template v-slot:item.assigned_to_user.name="{ item }">
            <v-tooltip
              v-if="item && item.assigned_to_user && item.assigned_to_user.name"
              top
              color="white"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  size="30"
                  color="error"
                  class="v-avatar-light-bg success--text"
                  v-on="on"
                >
                  <v-img
                    v-if="item && item.assigned_to_user && item.assigned_to_user.url_photo"
                    :src="item.assigned_to_user.base64"
                  />
                  <span
                    v-else
                    class="v-avatar-light-bg success--text"
                    style="font-size: 13px;"
                  >
                    {{ getInitials(item.assigned_to_user) }}
                  </span>
                </v-avatar>
              </template>
              <span style="color: black;">
                {{ item && item.assigned_to_user && item.assigned_to_user.name ? item.assigned_to_user.name : '-' }}
              </span>
            </v-tooltip>
            <span v-else>-</span>
          </template>
          <template v-slot:item.visualizar="{ item }">
            <v-btn
              icon
              @click="openSolicitationViewModal(item)"
            >
              <v-icon>
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </v-btn>
          </template>
          <template
            v-slot:no-data
          >
            <div class="my-10">
              <NoDataInterfaceEditable
                title="Nenhum  registro encontrado!"
                subtitle="Por favor, verifique os filtros de pesquisa / Contacte a equipe de desenvolvimento."
              />
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-dialog
      :key="ModalViewSolicitationKey"
      v-model="showModalViewSolicitation"
      width="1200px"
      transition="dialog-transition"
    >
      <ModalViewSolicitation
        :item="solicitation"
        :responsibles="responsibles"
        :third-parties="thirdParties"
        @close="showModalViewSolicitation = false"
        @get-table-data="handleUpdateData"
      />
    </v-dialog>
    <v-dialog
      v-model="showModalFilterSolicitation"
      width="600px"
      transition="dialog-transition"
    >
      <ModalFilterSolicitation
        ref="ModalFilterSolicitation"
        :active-tab="FilterActiveTab"
        :companies="companies"
        :requesters="requesters"
        :responsibles="responsibles"
        @close="showModalFilterSolicitation = false"
        @filterData="handleFilterData"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import NoDataInterfaceEditable from '@/components/charts/NoDataInterfaceEditable.vue'
import localStorageSlim from 'localstorage-slim'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/plugins/axios'
import {
  mdiAlertOctagramOutline,
  mdiBullhornOutline,
  mdiCheck,
  mdiCheckboxMarkedCircleOutline,
  mdiClockTimeFourOutline,
  mdiCropSquare,
  mdiDraw,
  mdiEyeOutline,
  mdiFilterVariant,
  mdiFormatListChecks,
  mdiImageOutline,
  mdiLightbulbOnOutline,
  mdiMagnify,
  mdiMessageOutline,
  mdiStorefrontOutline,
  mdiTimerSand,
  mdiVideoOutline,
  mdiVoicemail,
} from '@mdi/js'
import ModalFilterSolicitation from './modais/ModalFilterSolicitation.vue'
import ModalViewSolicitation from './modais/ModalViewSolicitation.vue'

export default {
  components: {
    NoDataInterfaceEditable, ModalViewSolicitation, ModalFilterSolicitation,
  },
  data() {
    return {
      isFiltered: false,
      showModalFilterSolicitation: false,
      icons: {
        mdiFilterVariant,
        mdiMagnify,
        mdiAlertOctagramOutline,
        mdiTimerSand,
        mdiLightbulbOnOutline,
        mdiFormatListChecks,
        mdiCheckboxMarkedCircleOutline,
        mdiEyeOutline,
        mdiBullhornOutline,
        mdiDraw,
        mdiVideoOutline,
        mdiCropSquare,
        mdiImageOutline,
        mdiMessageOutline,
        mdiVoicemail,
      },
      showModalViewSolicitation: false,
      ModalViewSolicitationKey: false,
      listOfFilteredItems: [],
      itemsTable: [],

      tabs: [
        {
          title: 'Novas Solicitações',
          key: 'new_solicitations',
          isActive: false,
          icon: mdiAlertOctagramOutline,
          badgeBackgroundColor: '#E7E3FC1A',
          badgeTextColor: '#E7E3FC',
          solicitation_quantity: 0,
        },
        {
          title: 'Em Andamento',
          key: 'in_progress',
          isActive: false,
          icon: mdiTimerSand,
          badgeBackgroundColor: '#16B1FF1A',
          badgeTextColor: '#16B1FF',
          solicitation_quantity: 0,
        },
        {
          title: 'Em execução com terceiro',
          key: 'in_execution',
          isActive: false,
          icon: mdiLightbulbOnOutline,
          badgeBackgroundColor: '#5FFFD21A',
          badgeTextColor: '#4DD6AF',
          solicitation_quantity: 0,
        },
        {
          title: 'Enviadas para validação',
          key: 'awaiting_approval',
          isActive: false,
          icon: mdiFormatListChecks,
          badgeBackgroundColor: '#FFB4001A',
          badgeTextColor: '#FFB400',
          solicitation_quantity: 0,
        },
        {
          title: 'Enviada ao setor de compras',
          key: 'sent',
          isActive: false,
          icon: mdiStorefrontOutline,
          badgeBackgroundColor: '#C48EFF1A',
          badgeTextColor: '#C48EFF',
          solicitation_quantity: 0,
        },
        {
          title: 'Finalizada',
          key: 'completed',
          isActive: false,
          icon: mdiCheckboxMarkedCircleOutline,
          badgeBackgroundColor: '#56CA001A',
          badgeTextColor: '#56CA00',
          solicitation_quantity: 0,
        },
      ],
      SecondaryTabs: [
        {
          title: 'Em validação',
          key: 'in_validation',
          isActive: false,
          icon: mdiClockTimeFourOutline,
          badgeBackgroundColor: '#ffce1f1A',
          badgeTextColor: '#ffce1f',
          solicitation_quantity: 0,
        },
        {
          title: 'Validadas',
          key: 'validated',
          isActive: false,
          icon: mdiCheck,
          badgeBackgroundColor: '#a3de001A',
          badgeTextColor: '#a3de00',
          solicitation_quantity: 0,
        },
      ],
      headers: [
        {
          text: 'N° SLM',
          value: 'slm',
          sortable: true,
          align: 'left',
        },
        {
          text: 'DATA DA SOLICITAÇÃO',
          value: 'created_at',
          sortable: false,
          align: 'left',
        },
        {
          text: 'SOLICITANTE',
          value: 'created_by_user.name',
          sortable: true,
          align: 'left',
        },
        {
          text: 'ASSUNTO',
          value: 'subject',
          sortable: true,
          align: 'left',
        },
        {
          text: 'RESPONSÁVEL',
          value: 'assigned_to_user.name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'VISUALIZAR',
          value: 'visualizar',
          sortable: false,
          align: 'center',
        },
      ],
      solicitation: {},
      responsibles: [],
      companies: [],
      requesters: [],
      thirdParties: [],
      loadingTable: false,
      solicitationQuantity: {},
      FilterActiveTab: {},
      userProfile: {},
    }
  },
  computed: {
    filterButtonColor() {
      if (!this.isDark) {
        return this.isFiltered ? 'success' : 'black'
      }

      return this.isFiltered ? 'success' : 'white'
    },
    clearButtonColor() {
      if (!this.isDark) {
        return this.isFiltered ? 'error' : 'black'
      }

      return this.isFiltered ? 'error' : 'white'
    },
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
  created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })

    this.getSolicitationQuantity()
    this.setActiveTab(0)
    this.getResponsibles()
    this.getThirdParties()
    this.getCompanies()
    this.getRequesters()
  },

  methods: {
    getInitials(user) {
      if (!user || !user.name) {
        return '-'
      }
      const names = user.name.split(' ')

      if (names.length === 1) {
        return names[0].charAt(0).toUpperCase()
      }
      const firstInitial = names[0].charAt(0).toUpperCase()
      const lastInitial = names[names.length - 1].charAt(0).toUpperCase()

      return firstInitial + lastInitial
    },
    clearFilters() {
      this.$refs.ModalFilterSolicitation.handleClearFilter()
    },
    handleFilterData(data) {
      this.listOfFilteredItems = data.data
      this.isFiltered = data.isFiltered
    },
    openSolicitationViewModal(solicitation) {
      this.generateNewModalViewKey()
      this.solicitation = solicitation
      this.showModalViewSolicitation = true
    },
    generateNewModalViewKey() {
      this.ModalViewSolicitationKey = Math.random()
    },
    async getResponsibles() {
      try {
        const { data } = await axiosIns.get('/api/v1/solicitations/marketing/get_responsibles_list')
        this.responsibles = data.data
      } catch (error) {
        console.error('Erro ao buscar lista de responsáveis', error)
      }
    },
    async getCompanies() {
      try {
        const { data } = await axiosIns.get('/api/v1/records/company/index?page=1')
        this.companies = data.data
      } catch (error) {
        console.error(' Erro ao buscar lista de empresas', error)
      }
    },
    async getRequesters() {
      try {
        const { data } = await axiosIns.get('/api/v1/solicitations/marketing/get_requesters')
        this.requesters = data.data
      } catch (error) {
        console.error('Erro ao buscar a lista de solicitantes', error)
      }
    },
    openshowModalFilterSolicitation() {
      this.showModalFilterSolicitation = true
    },
    async getThirdParties() {
      try {
        const { data } = await axiosIns.get('/api/v1/solicitations/marketing_third_parties/index')
        this.thirdParties = data.data
      } catch (error) {
        console.error('Erro ao buscar lista de terceiros', error)
      }
    },

    formatToDayMonthYear(isoDate) {
      const date = new Date(isoDate)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()

      return `${day}/${month}/${year}`
    },
    async getSolicitationQuantity() {
      try {
        const res = await axiosIns.get('/api/v1/solicitations/marketing/get_solic_numb')

        const { data } = res.data || {}

        this.tabs = this.tabs.map(tab => ({
          ...tab,
          solicitation_quantity: data[tab.key] || 0,
        }))
      } catch (error) {
        console.error('Erro ao buscar quantidades de solicitações:', error)
      }
    },
    async setActiveTab(index) {
      this.tabs.forEach(tab => {
        // eslint-disable-next-line no-param-reassign
        tab.isActive = false
      })
      this.tabs[index].isActive = true

      if (this.$refs.ModalFilterSolicitation) {
        this.$refs.ModalFilterSolicitation.handleClearFilterFields()
        this.handleGetDataByActiveTab(this.tabs[index].key)

        return
      }
      this.FilterActiveTab = this.tabs[index]
      await this.handleGetDataByActiveTab(this.tabs[index].key)
      if (this.tabs[index].key === 'awaiting_approval') {
        this.setSecondaryActiveTab(0)
      }
    },
    setSecondaryActiveTab(index) {
      this.SecondaryTabs.forEach(tab => {
        // eslint-disable-next-line no-param-reassign
        tab.isActive = false
      })
      this.SecondaryTabs[index].isActive = true

      const selectedTab = this.SecondaryTabs[index].key

      const itemsInValidation = this.itemsTable.filter(item => item.has_been_validated === false)
      this.SecondaryTabs[0].solicitation_quantity = itemsInValidation.length

      const itemsValidated = this.itemsTable.filter(item => item.has_been_validated === true)
      this.SecondaryTabs[1].solicitation_quantity = itemsValidated.length

      if (selectedTab === 'in_validation') {
        this.listOfFilteredItems = itemsInValidation
      } else if (selectedTab === 'validated') {
        this.listOfFilteredItems = itemsValidated
      }
    },
    returnIconOrColorBasedOnSubject(whichOne, subject) {
      if (whichOne === 'icon') {
        const subjectMap = {
          'AÇÃO DE VENDAS': mdiBullhornOutline,
          'BANNER/MATERIAL DIGITAL': mdiCropSquare,
          'PRODUÇÃO DE MATERIAL': mdiImageOutline,
          'PRODUÇÃO DE VÍDEOS OU FOTOS': mdiVideoOutline,
          'COMUNICAÇÃO INTERNA': mdiVoicemail,
          'MENSAGEM TEMPLATE': mdiMessageOutline,
          'ASSINATURA DE E-MAIL': mdiDraw,
        }

        const subjectData = subjectMap[subject]

        return subjectData || ''
      }

      if (whichOne === 'color') {
        const subjectColorMap = {
          'AÇÃO DE VENDAS': '#FFB400',
          'BANNER/MATERIAL DIGITAL': '#9A62FD',
          'PRODUÇÃO DE MATERIAL': '#56CA00',
          'PRODUÇÃO DE VÍDEOS OU FOTOS': '#FF4C51',
          'COMUNICAÇÃO INTERNA': '#EF70FF',
          'MENSAGEM TEMPLATE': '#FFE500',
          'ASSINATURA DE E-MAIL': '#16B1FF',
        }

        const subjectColor = subjectColorMap[subject]

        return subjectColor || '#000000'
      }

      console.error('Invalid value for whichOne:', whichOne)

      return null
    },

    handleUpdateData() {
      this.getSolicitationQuantity()
      this.handleGetDataByActiveTab('new_solicitations')
      this.setActiveTab(0)
    },

    async handleGetDataByActiveTab(key) {
      this.loadingTable = true
      try {
        const res = await axiosIns.get(`/api/v1/solicitations/marketing/get/${key}`)
        this.itemsTable = res.data.data
        this.listOfFilteredItems = this.itemsTable
      } catch (error) {
        console.error(`Ocorreu um erro ao buscar os dados dessa key:${key}`, error)
      } finally {
        this.loadingTable = false
      }
    },
  },
}
</script>

<style scoped>
.button-text-actions{
  font-size: 14px;
  font-weight: 600;
}
.isActive {
  border-left: 4px solid #9a62fd;
}
.isntActive {
  border-left: 4px solid transparent;
}
.isSecondaryActive {
  border-bottom: 2px solid #9a62fd;
}
.isntSecondaryActive {
  border-bottom: 2px solid transparent;
}
.isActiveText {
  color: #9A62FD;
  font-weight: 700;
}
</style>
