import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userProfile.occupation_sector.sector.name === 'MARKETING')?_c(VCard,{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-space-between pa-7",staticStyle:{"width":"100%"}},[_c('h3',{staticStyle:{"font-size":"18px","font-weight":"600"}},[_vm._v(" Painel de solicitações ")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"20px"}},[_c(VBtn,{attrs:{"outlined":"","color":_vm.filterButtonColor},on:{"click":_vm.openshowModalFilterSolicitation}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")]),_c('span',{staticClass:"button-text-actions"},[_vm._v("FILTRAR")])],1),(_vm.isFiltered)?_c(VBtn,{attrs:{"outlined":"","color":"error"},on:{"click":_vm.clearFilters}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")]),_c('span',{staticClass:"button-text-actions"},[_vm._v("LIMPAR")])],1):_vm._e()],1)]),_c('div',{staticClass:"d-flex pr-7"},[_c('div',{staticStyle:{"width":"40%"}},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex py-2 align-center",class:{ isActive: tab.isActive, isntActive: !tab.isActive },staticStyle:{"gap":"10px","width":"auto"}},[_c(VIcon,{staticClass:"ml-3",class:{ isActiveText: tab.isActive },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.setActiveTab(index)}}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',{class:{ isActiveText: tab.isActive },staticStyle:{"font-size":"16px","cursor":"pointer"},on:{"click":function($event){return _vm.setActiveTab(index)}}},[_vm._v(_vm._s(tab.title))]),_c('div',{staticClass:"d-flex px-3 py-1",staticStyle:{"padding":"10px 10px","align-items":"center","justify-content":"center","border-radius":"30px","width":"35px","cursor":"pointer"},style:({
              background: tab.badgeBackgroundColor,
            }),on:{"click":function($event){return _vm.setActiveTab(index)}}},[_c('span',{staticStyle:{"font-size":"12px","font-weight":"500"},style:({ color: tab.badgeTextColor })},[_vm._v(_vm._s(tab.solicitation_quantity))])])],1)])}),0),_c('div',{staticStyle:{"border-radius":"10px","width":"100%"}},[(_vm.FilterActiveTab.key === 'awaiting_approval')?_c('div',{staticClass:"d-flex px-16 mb-4",staticStyle:{"justify-content":"center","align-items":"center","gap":"50px"}},_vm._l((_vm.SecondaryTabs),function(tab,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex py-2 align-center",staticStyle:{"gap":"10px","width":"auto"}},[_c(VIcon,{class:{ isActiveText: tab.isActive },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.setSecondaryActiveTab(index)}}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',{class:{ isActiveText: tab.isActive, isSecondaryActive: tab.isActive, isntSecondaryActive: !tab.isActive },staticStyle:{"font-size":"16px","cursor":"pointer"},on:{"click":function($event){return _vm.setSecondaryActiveTab(index)}}},[_vm._v(_vm._s(tab.title))]),_c('div',{staticClass:"d-flex px-3 py-1",staticStyle:{"padding":"10px 10px","align-items":"center","justify-content":"center","border-radius":"30px","width":"35px","cursor":"pointer"},style:({
                background: tab.badgeBackgroundColor,
              }),on:{"click":function($event){return _vm.setSecondaryActiveTab(index)}}},[_c('span',{staticStyle:{"font-size":"12px","font-weight":"500"},style:({ color: tab.badgeTextColor })},[_vm._v(_vm._s(tab.solicitation_quantity))])])],1)])}),0):_vm._e(),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.listOfFilteredItems,"loading":_vm.loadingTable,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.slm",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticStyle:{"color":"#9A62FD","font-weight":"500","font-size":"14px"}},[_vm._v(" 0"+_vm._s(item.slm ? item.slm : '')+" ")])]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.created_at ? _vm.formatToDayMonthYear(item.created_at) : 'A definir')+" ")])]}},{key:"item.subject",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex my-6",staticStyle:{"align-items":"center","gap":"10px"}},[_c(VIcon,{attrs:{"color":_vm.returnIconOrColorBasedOnSubject('color', item.subject)}},[_vm._v(" "+_vm._s(_vm.returnIconOrColorBasedOnSubject('icon', item.subject))+" ")]),_c('span',[_vm._v(" "+_vm._s(item.subject ? item.subject : '-')+" ")])],1)]}},{key:"item.assigned_to_user.name",fn:function(ref){
              var item = ref.item;
return [(item && item.assigned_to_user && item.assigned_to_user.name)?_c(VTooltip,{attrs:{"top":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"v-avatar-light-bg success--text",attrs:{"size":"30","color":"error"}},'v-avatar',attrs,false),on),[(item && item.assigned_to_user && item.assigned_to_user.url_photo)?_c(VImg,{attrs:{"src":item.assigned_to_user.base64}}):_c('span',{staticClass:"v-avatar-light-bg success--text",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.getInitials(item.assigned_to_user))+" ")])],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(item && item.assigned_to_user && item.assigned_to_user.name ? item.assigned_to_user.name : '-')+" ")])]):_c('span',[_vm._v("-")])]}},{key:"item.visualizar",fn:function(ref){
              var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.openSolicitationViewModal(item)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-10"},[_c('NoDataInterfaceEditable',{attrs:{"title":"Nenhum  registro encontrado!","subtitle":"Por favor, verifique os filtros de pesquisa / Contacte a equipe de desenvolvimento."}})],1)]},proxy:true}],null,false,854680699)})],1)]),_c(VDialog,{key:_vm.ModalViewSolicitationKey,attrs:{"width":"1200px","transition":"dialog-transition"},model:{value:(_vm.showModalViewSolicitation),callback:function ($$v) {_vm.showModalViewSolicitation=$$v},expression:"showModalViewSolicitation"}},[_c('ModalViewSolicitation',{attrs:{"item":_vm.solicitation,"responsibles":_vm.responsibles,"third-parties":_vm.thirdParties},on:{"close":function($event){_vm.showModalViewSolicitation = false},"get-table-data":_vm.handleUpdateData}})],1),_c(VDialog,{attrs:{"width":"600px","transition":"dialog-transition"},model:{value:(_vm.showModalFilterSolicitation),callback:function ($$v) {_vm.showModalFilterSolicitation=$$v},expression:"showModalFilterSolicitation"}},[_c('ModalFilterSolicitation',{ref:"ModalFilterSolicitation",attrs:{"active-tab":_vm.FilterActiveTab,"companies":_vm.companies,"requesters":_vm.requesters,"responsibles":_vm.responsibles},on:{"close":function($event){_vm.showModalFilterSolicitation = false},"filterData":_vm.handleFilterData}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }